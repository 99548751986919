import React from "react"
import { graphql } from "gatsby"
import Connection from "../components/Connection"

const ServePageWIP = ({data}) => (
  <Connection
    context             = "serve"
    seoTitle            = "Serve"
    // pageTitlePrefix     = "Connect"
    pageTitlePrefix     = "Serve"
    pageTitle           = {<>Find opportunities to <span>serve</span> our communities</>}
    noRecordsIndexTitle = "No Results"
    noRecordsIndexText  = "trying another selection to find a service opportunity."
    collection          = {data.allDatoCmsConnection.edges}
  />
)

export default ServePageWIP


// GraphQL
// -------

export const query = graphql`
  query ServeQueryIndexWIP {
    allDatoCmsConnection(
      filter: {connectionType: {slug: {eq: "serve"}}}
    ) {
      edges {
        node {
          ...ConnectionFragment
        }
      }
    }
  }
`
